<template>
    <contenWrap>
        <div class="storeAccountManager">
            <div class="tool-card">
                <div class="add-btn-wrap">
                    <CreateBtn  name='新建门店' @cbCreateBtnClick="handleCreateStore"></CreateBtn>
                </div>
            </div>
            <div class="table-card">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="shopName" label="门店名称" width="width"></el-table-column>
                    <el-table-column prop="prop" label="门店分类" width="width">
                        <template slot-scope="scope">
                            <div v-if="scope.row.type == 0">直营</div>
                            <div v-if="scope.row.type == 1">非直营</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="shopManager" label="店长姓名" width="width"></el-table-column>
                    <el-table-column prop="address" label="门店地址" width="width"></el-table-column>
                    <el-table-column prop="remark" label="备注" width="width"></el-table-column>
                    <el-table-column prop="createTime" label="创建时间" width="width"></el-table-column>
                    <el-table-column label="操作" width="width" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="small"  @click="handleClickEdit(scope.row)">编辑</el-button>
                            <el-button type="text" size="small"  @click="handleClickDel(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <div class="page-wrap">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currpage"
                    :page-sizes="[5, 10, 20, 50, 100]"
                    :page-size="pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalRow"
                >
                </el-pagination>
        </div>
            </div>
        </div>

        <!-- 新建门店dialog -->
        <el-dialog
            title="添加门店"
            :visible.sync="isShowAddStoreDialog"
            width="650px"
            :close-on-click-modal="false"  
            :before-close="handleClose"
            class="addStoreDialog"
            v-loading="loading"
        >
            <div class="content">
                <el-form ref="createStoreFormRef" :rules="createStoreFormRules" :model="createStoreForm" label-width="100px">
                    <el-form-item label="门店名称" prop="shopName">
                        <el-input v-model="createStoreForm.shopName" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="门店分类" prop="type">
                        <el-select v-model="createStoreForm.type" placeholder="请选择门店分类">
                            <el-option
                            v-for="item in classifyOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>   
                    <el-form-item label="店长姓名" prop="shopManager">
                        <el-input v-model="createStoreForm.shopManager"  maxlength="10" show-word-limit></el-input>
                    </el-form-item>   
                    <el-form-item label="联系电话" prop="phone">
                        <el-input v-model="createStoreForm.phone"
                            maxlength="11" show-word-limit
                        ></el-input>
                    </el-form-item>  
                    <el-form-item label="登录账号" prop="userName">
                        <el-input v-model="createStoreForm.userName"  maxlength="10" show-word-limit></el-input>
                    </el-form-item>    
                    <el-form-item label="登录密码" prop="password" >
                        <el-input v-model="createStoreForm.password"  maxlength="10" show-word-limit></el-input>
                    </el-form-item>      
                    <el-form-item label="门店地址" prop="address">
                        <el-input v-model="createStoreForm.address" maxlength="30" show-word-limit></el-input>
                    </el-form-item>   
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" :rows="2" v-model="createStoreForm.remark"></el-input>
                    </el-form-item> 
                    <!-- <el-form-item label="产品审核">
                        <div class="tablescope-wrap">
                            <el-switch
                                v-model="isAudit"
                                active-text="开"
                                inactive-text="关"
                                @change="switchChange"
                            >
                            </el-switch>
                        </div>
                    </el-form-item>                                                                        -->
                </el-form>
            </div>
            <div class="el-dialog__footer">
                <div class="dialog-footer-cancle" @click="resetForm">取 消</div>
                <div class="dialog-footer-submit" @click="submitFormEdit" v-if="isShowBnt">修改</div>
                <div class="dialog-footer-submit" @click="submitFormNew" v-else>新建</div>
            </div>
        </el-dialog>        
    </contenWrap>
</template>

<script>
import contenWrap from '@/components/contenWrap'
import pageMixin from '@/mixin/pageMixin'
import CreateBtn from '@/components/CreateBtn'
export default {
    mixins:[pageMixin],
    components: {
        contenWrap,
        CreateBtn
    },
    data() {
        var passwords = (rule, value, callback) => {
            if(!this.isShowBnt){
                if (!value) {
                    return callback(new Error('请输入登录密码'));
                }else{
                    if(value.length < 6 ){
                        callback(new Error('登录密码不能少于6位字符'));
                    }
                    callback();
                }
            }
            callback();
        };
        return {
            pageMixin_index:'storeAccountManager',
            tableData:[],
            isShowAddStoreDialog:false,
            createStoreForm:{
                shopName: '',
                type: null,
                shopManager: '',
                phone: '',
                userName: '',
                password: '',
                address: '',
                remark: '',
                audit: 1
            },
            isShowBnt: false,
            isAudit: true,
            classifyOptions: [{
                value: 0,
                label: '直营'
            },{
                value: 1,
                label: '非直营'
            }],
            createStoreFormRules: {
                shopName: [
                    { required: true, message: '请输入门店名称', trigger: 'blur' },
                ],
                type: [
                    { required: true, message: '请选择门店分类', trigger: 'change' }
                ],
                shopManager: [
                    { required: true, message: '请输入店长姓名', trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' },
                    { min: 11, message: '电话号码不能少于11个字符', trigger: 'blur' },
                ],
                userName: [
                    { required: true, message: '请输入登录账号', trigger: 'blur' },
                ],
                password: [
                    // { required: true, message: '请输入登录密码', trigger: 'blur' },
                    { validator: passwords, trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '请输入门店地址', trigger: 'blur' },
                ]
            },
            currpage: 1,
            pagesize: 5,
            totalRow: 0,
            loading:false
        }
    },
    mounted(){
        this.getShopList()
    },
    methods: {
        //编辑
        handleClickEdit(row){
            let params = {
                shopId: row.id
            }
            this.$API.getShopInfo(params).then( res => {
                if(res.code === 0){
                    this.isShowAddStoreDialog = true;
                    this.createStoreForm = res.data;
                    this.isShowBnt = true;
                    this.createStoreForm.shopId = res.data.id
                }
            })
        },
        //删除
        handleClickDel(row){
            let list = []
            list.push(row.id )
            let params = {
                ids: list
            }
            this.$confirm('是否要删除该门店?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                this.$API.deleteShop(params).then( res => {
                    if(res.code === 0){
                        this.getShopList();
                    }
                })
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
            
        },
        //关闭弹窗
        handleClose(){
            this.isShowAddStoreDialog = false;
            this.isShowBnt = false;
            this.createStoreForm = {}
            this.$refs.createStoreFormRef.resetFields();
        },
        //获取门店列表数据
        getShopList(){
            let params = {
                keyword: '',
                currentPage: this.currpage,
                pageSize: this.pagesize
            }
            this.$API.shopList(params).then( res => {
                console.log(res);
                if(res.code === 0){
                    this.tableData = res.data.list;
                    this.totalRow = res.data.pageInfo.totalRow
                }
            })
        },
        //修改
        submitFormEdit(){
            this.$refs.createStoreFormRef.validate((boolean)=>{
                if(!boolean){
                    this.$message.warning('门店详情未完善')
                }else{
                    this.$API.updateShop(this.createStoreForm).then( res => {
                        if(res.code === 0){
                            this.getShopList();
                            this.$message.success("修改门店成功！");
                            this.isShowAddStoreDialog = false;
                            this.$refs.createStoreFormRef.resetFields();
                        }
                    })
                } 
            })
        },
        //新建提交
        submitFormNew() {
            this.$refs.createStoreFormRef.validate((valid) => {
                if (valid) {
                    this.loading = true
                    this.$API.shopAdd(this.createStoreForm).then( res => {
                        this.loading = false
                        if(res.code === 0){
                            this.getShopList();
                            this.$message.success("添加门店成功！");
                            this.isShowAddStoreDialog = false;
                            this.$refs.createStoreFormRef.resetFields();
                        }else{
                            this.$message.warning('系统内部错误')
                        }
                    })
                }
            });
        },
        switchChange(e){
            if(e){
                this.createStoreForm.audit = 1
            }else{
                this.createStoreForm.audit = 0
            }
        },
        //取消
        resetForm() {
            this.isShowAddStoreDialog = false;
            this.isShowBnt = false;
            this.createStoreForm = {}
            this.$refs.createStoreFormRef.resetFields();
        },
        handleCreateStore(){
            this.isShowAddStoreDialog = true
        },
        handleCurrentChange(cpage) {
            this.currpage = cpage;
            this.getShopList();
        },
        handleSizeChange(psize) {
            this.pagesize = psize;
            this.getShopList();
        },
    }
}
</script>

<style lang="scss">
.storeAccountManager{
    .tool-card{
        padding: 10px 10px;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid $card-border-color;
    }
    .table-card{
        margin-top: 20px;
        padding: 10px 10px;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid $card-border-color;        
        .page-wrap{
            display: flex;
            justify-content: flex-end;
            padding: 20px 0;
        }
    }

}
.addStoreDialog{
    .content{
        padding: 0 60px 0 20px;
        //修改开关按钮样式
        .tablescope-wrap{
            .el-switch__label--left {
                position: relative;
                left: 50px;
                color: #fff;
                z-index: -1111;
            }
            .el-switch__core{
                width: 54px !important;
            }
            .el-switch__label--right {
                position: relative;
                right: 46px;
                color: #fff;
                z-index: -1111;
            }
            .el-switch__label--right.is-active {
                z-index: 1111;
                color: #fff !important;
            }
            .el-switch__label--left.is-active {
                z-index: 1111;
                color: #9c9c9c !important;
            }
        }   
        .el-input__inner{
            height: 35px !important;
            line-height: 35px;
        }
    }
}
</style>